import React from "react";
import "./QuittanceHtml.scss";
import LogoNoly from "../../../assets/images/logo.svg";
import { FormGroup } from "reactstrap";
import {
  IAvoir,
  IDevis,
  IFacture,
  IModel,
  IQuittance,
  IQuittanceModel,
  User,
} from "../../../interfaces";
import moment, { Moment } from "moment";
import { ClientTypes, OptionType } from "../../../util/context";
import { colors } from "../../../constants";
import CerleUserBg from "../../../assets/CerleUserBg";
moment.updateLocale("fr", {});

export interface FactHtmlProps {}

const FactureHtml = ({
  quittance,
  type,
  client,
}: {
  quittance: IQuittance | IQuittanceModel;
  type: string;
  client?: User;
}) => {
  const writtenNumber = require("written-number");

  const address = () => {
    switch (type) {
      case "model":
        return (
          (quittance as IQuittanceModel)?.modelInfo.companyAddress ||
          quittance?.company.headOffice
        );

      default:
        return (
          (quittance as IQuittance)?.quittInfo.companyAddress ||
          quittance?.company.headOffice
        );
    }
  };
  const phone = () => {
    switch (type) {
      case "model":
        return (
          (quittance as IQuittanceModel)?.modelInfo.companyPhone ||
          quittance?.company.phone
        );

      default:
        return (
          (quittance as IQuittance)?.quittInfo.companyPhone ||
          quittance?.company.phone
        );
    }
  };

  return (
    <>
      {quittance ? (
        <>
          <div className="facture-Wrrap">
            <h2 className="quittance-title">
              Quittance de loyer du mois de{" "}
              {quittance?.month
                ? moment(quittance?.month).format("MMMM yyyy")
                : ""}
            </h2>
            <div className="Header-fact-view">
              <div className="left-head-fact">
                <div className="titleNUm-fact">
                  <span className="titlenum-fact">Quittance n° :</span>
                  <span className="num-fact">
                    {quittance?.id.toUpperCase()}
                  </span>
                  <span className="date-fact">
                    Date: {moment(quittance?.date).format("DD/MM/YYYY")}
                  </span>
                </div>
              </div>
              <div className="right-head-fact">
                <div className="invoice-logo user-info mb-4">
                  {type === "model" ? (
                    <>
                      {(quittance as IQuittanceModel)?.modelInfo &&
                        (quittance as IQuittanceModel)?.modelInfo.logo && (
                          <>
                            <CerleUserBg
                              className={"bgCercle"}
                              fillColor={colors.primaryColor}
                            />
                            <img
                              src={
                                (quittance as IQuittanceModel)?.modelInfo.logo
                              }
                              alt="logo"
                            />
                          </>
                        )}
                    </>
                  ) : (
                    <>
                      {(quittance as IQuittance)?.quittInfo &&
                        (quittance as IQuittance)?.quittInfo.logo && (
                          <>
                            <CerleUserBg
                              className={"bgCercle"}
                              fillColor={colors.primaryColor}
                            />
                            <img
                              src={(quittance as IQuittance)?.quittInfo.logo}
                              alt="logo"
                            />
                          </>
                        )}
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="Header-fact-infos">
              <div className="right-infos-fact">
                <div className="box-fact-infos box-primary">
                  <h3>{quittance?.company.name}</h3>
                  <div className="ligne-infos">
                    <span className="label-title">Adresse :</span>
                    <span className="label-content">{address()}</span>
                  </div>
                  <div className="ligne-infos">
                    <span className="label-title">Teléphone :</span>
                    <span className="label-content">
                      {phone() || client?.phone}
                    </span>
                  </div>
                  <div className="ligne-infos">
                    <span className="label-title">Siren :</span>
                    <span className="label-content">
                      {" "}
                      {quittance?.company.siren}
                    </span>
                  </div>
                  <div className="ligne-infos">
                    <span className="label-title">Numéro TVA :</span>
                    <span className="label-content">
                      {" "}
                      {quittance?.company.intraCommunityVATNumber}
                    </span>
                  </div>
                  <div className="ligne-infos">
                    <span className="label-title">Capital social :</span>
                    <span className="label-content">
                      {" "}
                      {quittance?.company.shareCapital}
                    </span>
                  </div>
                </div>
              </div>
              <div className="left-infos-fact">
                <div className="box-fact-infos ">
                  <h3>
                    {quittance?.clientInfo.clientName ||
                      quittance?.clientInfo.clientCompany}
                  </h3>
                  <div className="ligne-infos">
                    <span className="label-title">Adresse :</span>
                    <span className="label-content">
                      {quittance?.clientInfo.clientAdress}
                    </span>
                  </div>
                  <div className="ligne-infos">
                    <span className="label-title">Teléphone :</span>
                    <span className="label-content">
                      {quittance?.clientInfo.clientTel}
                    </span>
                  </div>
                  {quittance?.clientType &&
                  quittance?.clientType === ClientTypes.B2B ? (
                    <>
                      <div className="ligne-infos">
                        <span className="label-title">Siren :</span>
                        <span className="label-content">
                          {quittance?.clientInfo.clientSiren}
                        </span>
                      </div>
                      <div className="ligne-infos">
                        <span className="label-title">Numéro TVA :</span>
                        <span className="label-content">
                          {quittance?.clientInfo.clientTVA}
                        </span>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>

            <div className="infos-additionnels">
              <div className="text-added">
                <p className="form-fact quitance-form">
                  Je soussigné(e),{" "}
                  {type === "model"
                    ? (quittance as IQuittanceModel).modelInfo.signee
                    : (quittance as IQuittance).quittInfo.signee}{" "}
                  , représentant(e) de la{" "}
                  <span>
                    {quittance?.company && quittance?.company?.name
                      ? quittance?.company?.name
                      : ""}
                  </span>
                  , propriétaire du logement désigné ci-dessus, déclare avoir
                  reçu de{" "}
                  {type === "model"
                    ? (quittance as IQuittanceModel).modelInfo.beneficiary
                    : (quittance as IQuittance).quittInfo.beneficiary}{" "}
                  , la somme de {quittance?.totalTTC} € (
                  {writtenNumber(parseFloat(quittance?.totalTTC), {
                    lang: "fr",
                  })}{" "}
                  euros), au titre du paiement du loyer et des charges pour la
                  période de location du{" "}
                  {moment(quittance?.startDate).format("DD/MM/YYYY")} au{" "}
                  {moment(quittance?.endDate).format("DD/MM/YYYY")} et lui en
                  donne quittance, sous réserve de tous mes droits.
                </p>
              </div>
            </div>

            <div className="quittance-resultat-table">
              <span className="title">Détail du règlement : </span>
              <div className="inner-total">
                <div className="quittance-item-totat">
                  <span className="label-total">Loyer : </span>
                  <span className="num-total">{quittance?.loyer} €</span>
                </div>
                <div className="quittance-item-totat">
                  <span className="label-total">Provision pour charges : </span>
                  <span className="num-total">{quittance?.charges} €</span>
                </div>
                {quittance.totalTVA && (
                  <div className="quittance-item-totat">
                    <span className="label-total">TVA : </span>
                    <span className="num-total">{quittance?.totalTVA} €</span>
                  </div>
                )}
                <div className="quittance-item-totat total-ttc">
                  <span className="label-total">Total :</span>
                  <span className="num-total">{quittance?.totalTTC} €</span>
                </div>
                <div>
                  <div className="quittance-item-totat">
                    <span className="label-total">Date du paiement : le </span>
                    <span className="num-total quitance-payment-date">
                      {moment(quittance?.paymentDate).format("DD/MM/YYYY")}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-fact">
              <span>Fiinex</span>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default FactureHtml;

import React from "react";
import "./FactureHtml.scss";
import LogoNoly from "../../../assets/images/logo.svg";
import { FormGroup } from "reactstrap";
import { IAvoir, IDevis, IFacture, IModel, User } from "../../../interfaces";
import moment, { Moment } from "moment";
import { ClientTypes, OptionType } from "../../../util/context";
import { colors } from "../../../constants";
import CerleUserBg from "../../../assets/CerleUserBg";
moment.updateLocale("fr", {});

export interface FactHtmlProps {}

const FactureHtml = ({
  invoice,
  type,
  client,
}: {
  invoice: IFacture | IDevis | IAvoir | IModel;
  type: string;
  client?: User;
}) => {
  const address = () => {
    switch (type) {
      case "facture":
        return (
          (invoice as IFacture)?.billInfo.companyAddress ||
          invoice?.company.headOffice
        );
      case "devis":
        return (
          (invoice as IDevis)?.devisInfo.companyAddress ||
          invoice?.company.headOffice
        );
      case "avoir":
        return (
          (invoice as IAvoir)?.avoirInfo.companyAddress ||
          invoice?.company.headOffice
        );

      default:
        return (
          (invoice as IModel)?.modelInfo.companyAddress ||
          invoice?.company.headOffice
        );
    }
  };
  const phone = () => {
    switch (type) {
      case "facture":
        return (
          (invoice as IFacture)?.billInfo.companyPhone || invoice?.company.phone
        );
      case "devis":
        return (
          (invoice as IDevis)?.devisInfo.companyPhone || invoice?.company.phone
        );
      case "avoir":
        return (
          (invoice as IAvoir)?.avoirInfo.companyPhone || invoice?.company.phone
        );

      default:
        return (
          (invoice as IModel)?.modelInfo.companyPhone || invoice?.company.phone
        );
    }
  };
  return (
    <>
      <div className="facture-Wrrap">
        <div className="Header-fact-view">
          <div className="left-head-fact">
            <div className="titleNUm-fact">
              <span className="titlenum-fact">
                {type === "facture"
                  ? "Facture"
                  : type === "devis"
                  ? "Devis"
                  : type === "avoir"
                  ? "Avoir"
                  : "Model"}{" "}
                n° :
              </span>
              <span className="num-fact">{invoice?.id.toUpperCase()}</span>
              <span className="date-fact">
                Date: {moment(invoice?.date).format("DD/MM/YYYY")}
              </span>
            </div>
          </div>
          <div className="right-head-fact">
            <div className="invoice-logo user-info mb-4">
              {type === "facture" ? (
                <>
                  {(invoice as IFacture)?.billInfo.logo && (
                    <>
                      <CerleUserBg
                        className={"bgCercle"}
                        fillColor={colors.primaryColor}
                      />
                      <img
                        src={(invoice as IFacture)?.billInfo.logo}
                        alt="logo"
                      />
                    </>
                  )}
                </>
              ) : type === "devis" ? (
                <>
                  {(invoice as IDevis)?.devisInfo.logo && (
                    <>
                      <CerleUserBg
                        className={"bgCercle"}
                        fillColor={colors.primaryColor}
                      />
                      <img
                        src={(invoice as IDevis)?.devisInfo.logo}
                        alt="logo"
                      />
                    </>
                  )}
                </>
              ) : type === "avoir" ? (
                <>
                  {(invoice as IAvoir)?.avoirInfo.logo && (
                    <>
                      <CerleUserBg
                        className={"bgCercle"}
                        fillColor={colors.primaryColor}
                      />
                      <img
                        src={(invoice as IAvoir)?.avoirInfo.logo}
                        alt="logo"
                      />
                    </>
                  )}
                </>
              ) : (
                <>
                  {(invoice as IModel)?.modelInfo.logo && (
                    <>
                      <CerleUserBg
                        className={"bgCercle"}
                        fillColor={colors.primaryColor}
                      />
                      <img
                        src={(invoice as IModel)?.modelInfo.logo}
                        alt="logo"
                      />
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>

        <div className="Header-fact-infos">
          <div className="right-infos-fact">
            <div className="box-fact-infos box-primary">
              <h3>{invoice?.company.name}</h3>
              <div className="ligne-infos">
                <span className="label-title">Adresse :</span>
                <span className="label-content">{address()}</span>
              </div>
              <div className="ligne-infos">
                <span className="label-title">Teléphone :</span>
                <span className="label-content">
                  {phone() || client?.phone}
                </span>
              </div>
              <div className="ligne-infos">
                <span className="label-title">Siren :</span>
                <span className="label-content"> {invoice?.company.siren}</span>
              </div>
              <div className="ligne-infos">
                <span className="label-title">Numéro TVA :</span>
                <span className="label-content">
                  {" "}
                  {invoice?.company.intraCommunityVATNumber}
                </span>
              </div>
              <div className="ligne-infos">
                <span className="label-title">Capital social :</span>
                <span className="label-content">
                  {" "}
                  {invoice?.company.shareCapital}
                </span>
              </div>
            </div>
          </div>
          <div className="left-infos-fact">
            <div className="box-fact-infos ">
              <h3>
                {invoice?.clientInfo.clientName ||
                  invoice?.clientInfo.clientCompany}
              </h3>
              <div className="ligne-infos">
                <span className="label-title">Adresse :</span>
                <span className="label-content">
                  {invoice?.clientInfo.clientAdress}
                </span>
              </div>
              <div className="ligne-infos">
                <span className="label-title">Teléphone :</span>
                <span className="label-content">
                  {invoice?.clientInfo.clientTel}
                </span>
              </div>
              {invoice?.clientType &&
              invoice?.clientType === ClientTypes.B2B ? (
                <>
                  <div className="ligne-infos">
                    <span className="label-title">Siren :</span>
                    <span className="label-content">
                      {invoice?.clientInfo.clientSiren}
                    </span>
                  </div>
                  <div className="ligne-infos">
                    <span className="label-title">Numéro TVA :</span>
                    <span className="label-content">
                      {invoice?.clientInfo.clientTVA}
                    </span>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>

        <div className="infos-additionnels">
          <div className="text-added">
            <span className="label-text-added">
              Informations additionnelles :
            </span>
            <br />
            <div>
              {type === "facture" ? (
                <>
                  {(invoice as IFacture)?.billInfo?.additionalInfo
                    ?.split("\n")
                    .map((elt) => (
                      <p>{elt}</p>
                    ))}
                </>
              ) : type === "devis" ? (
                <>
                  {(invoice as IDevis)?.devisInfo?.additionalInfo
                    ?.split("\n")
                    .map((elt) => (
                      <p>{elt}</p>
                    ))}
                </>
              ) : type === "avoir" ? (
                <>
                  {(invoice as IAvoir)?.avoirInfo?.additionalInfo
                    ?.split("\n")
                    .map((elt) => (
                      <p>{elt}</p>
                    ))}
                </>
              ) : (
                <>
                  {(invoice as IModel)?.modelInfo?.additionalInfo
                    ?.split("\n")
                    .map((elt) => (
                      <p>{elt}</p>
                    ))}
                </>
              )}
            </div>
          </div>
        </div>

        <div className="table-fact table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>Catégorie</th>
                <th>Description</th>
                <th>Prix unit.HT</th>
                <th>Quantité</th>
                <th>%TVA</th>
                <th>Tot.TVA</th>
                <th>Tot.HT</th>
                <th>Tot.TTC</th>
              </tr>
            </thead>
            <tbody>
              {type === "facture" ? (
                <>
                  {(invoice as IFacture)?.items &&
                    (invoice as IFacture)?.items.length > 0 &&
                    (invoice as IFacture)?.items.map((elt, key) => (
                      <tr key={key}>
                        <td style={{ position: "relative" }}>
                          {elt.category && elt.category.name
                            ? elt.category.name
                            : ""}
                          <span
                            style={{
                              position: "absolute",
                              bottom: "0px",
                              left: "5px",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              width: "100%",
                            }}
                          >
                            {elt.otherCategory ? elt.otherCategory.title : null}
                          </span>
                        </td>
                        <td>{elt.description}</td>
                        <td>{elt.unitPrice} €</td>
                        <td>{elt.quantity}</td>
                        <td>{elt.tva}</td>
                        <td>{elt.totalTVA} €</td>
                        <td>{elt.totalHT} €</td>
                        <td>{elt.totalTTC} €</td>
                      </tr>
                    ))}
                </>
              ) : type === "devis" ? (
                <>
                  {(invoice as IDevis)?.items &&
                    (invoice as IDevis)?.items.length > 0 &&
                    (invoice as IDevis)?.items.map((elt, key) => (
                      <tr key={key}>
                        <td>
                          {elt.category && elt.category.name
                            ? elt.category.name
                            : ""}
                        </td>
                        <td>{elt.description}</td>
                        <td>{elt.unitPrice} €</td>
                        <td>{elt.quantity}</td>
                        <td>{elt.tva}</td>
                        <td>{elt.totalTVA} €</td>
                        <td>{elt.totalHT} €</td>
                        <td>{elt.totalTTC} €</td>
                      </tr>
                    ))}
                </>
              ) : type === "avoir" ? (
                <>
                  {(invoice as IAvoir)?.items &&
                    (invoice as IAvoir)?.items.length > 0 &&
                    (invoice as IAvoir)?.items.map((elt, key) => (
                      <tr key={key}>
                        <td>
                          {elt.category && elt.category.name
                            ? elt.category.name
                            : ""}
                        </td>
                        <td>{elt.description}</td>
                        <td>{elt.unitPrice} €</td>
                        <td>{elt.quantity}</td>
                        <td>{elt.tva}</td>
                        <td>{elt.totalTVA} €</td>
                        <td>{elt.totalHT} €</td>
                        <td>{elt.totalTTC} €</td>
                      </tr>
                    ))}
                </>
              ) : (
                <>
                  {(invoice as IModel)?.items &&
                    (invoice as IModel)?.items.length > 0 &&
                    (invoice as IModel)?.items.map((elt, key) => (
                      <tr key={key}>
                        <td style={{ position: "relative" }}>
                          {elt.category && elt.category.name
                            ? elt.category.name
                            : ""}
                          <span
                            style={{
                              position: "absolute",
                              bottom: "-3px",
                              left: "5px",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              width: "100%",
                            }}
                          >
                            {elt.otherCategory ? elt.otherCategory.title : null}
                          </span>
                        </td>
                        <td>{elt.description}</td>
                        <td>{elt.unitPrice} €</td>
                        <td>{elt.quantity}</td>
                        <td>{elt.tva}</td>
                        <td>{elt.totalTVA} €</td>
                        <td>{elt.totalHT} €</td>
                        <td>{elt.totalTTC} €</td>
                      </tr>
                    ))}
                </>
              )}
            </tbody>
          </table>
        </div>
        <div className="resultat-table">
          <div className="inner-total">
            <div className="item-totat">
              <span className="label-total">Total HT : </span>
              <span className="num-total">{invoice?.totalHT} €</span>
            </div>
            <div className="item-totat">
              <span className="label-total">Total TVA : </span>
              <span className="num-total">{invoice?.totalTVA} €</span>
            </div>
            <div className="item-totat total-ttc">
              <span className="label-total">Total TTC :</span>
              <span className="num-total">{invoice?.totalTTC} €</span>
            </div>
          </div>
        </div>
        {!["devis", "avoir"].includes(type) &&
          ((invoice as IFacture) || (invoice as IModel))?.legalFooter &&
          ((invoice as IFacture) || (invoice as IModel))?.legalFooter !==
            "" && (
            <div className="leaglFooter">
              {((invoice as IFacture) || (invoice as IModel))?.legalFooter
                .split("\n")
                .map((elt, index) => (
                  <p key={index}>{elt}</p>
                ))}
            </div>
          )}
        <div className="footer-fact">
          <span>Fiinex</span>
        </div>
      </div>
    </>
  );
};

export default FactureHtml;

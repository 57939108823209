import React, { useEffect, useId, useState } from "react";
import "./LoadQuittance.scss";
import LogoNoly from "../../../assets/images/logo.svg";

import IconPlusYellow from "../../../assets/images/svg/puls-icon-yellow.svg";
import Select from "react-select";
import {
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import {
  RiAddFill,
  RiCameraLine,
  RiCloseCircleFill,
  RiLinkM,
  RiSave3Fill,
} from "react-icons/ri";
import { IoMdCreate } from "react-icons/io";
import { BsTrashFill } from "react-icons/bs";
import SaveIconWhite from "../../../assets/SaveIconWhite";
import EmailIcon from "../../../assets/images/svg/mail-green.svg";
import moment from "moment";
import "moment/locale/fr";
import useAxios from "../../../util/hooks/useAxios";
import { useSelector } from "react-redux";
import { ErrorLogger } from "../../../util/errorLogger";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  Company,
  IAvoir,
  IDevis,
  IFacture,
  IModel,
  IQuittance,
  ISubCategory,
  User,
} from "../../../interfaces";
import { useFormatter } from "../../../util/hooks/useFormatter";
import { ClientTypes, OptionType } from "../../../util/context";
import CerleUserBg from "../../../assets/CerleUserBg";
import { colors } from "../../../constants";
import { FaFileInvoice } from "react-icons/fa";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import { ReactSVG } from "react-svg";
registerLocale("fr", fr);

const options = [
  {
    value: "Prestations de ser-vices a 20%",
    label: "Prestations de ser-vices a 20% de ser-vices a 20%",
  },
];
export interface FactureCreatProps {}

export type GeneralFormValues = {
  id: number | string;
  category: OptionType | null;
  description: string | null;
  unitPrice: number | null;
  quantity: number | null;
  tva: number | null;
  totalTVA: number | null;
  totalHT: number | null;
  totalTTC: number | null;
};

const LoadQuittance = ({
  formData,
  setFormData,
  needEmail,
  setNeedEmail,
  clientType,
  needModelRef,
  setNeedModelRef,
  invoice,
}: {
  formData: any;
  setFormData: any;
  needEmail: boolean;
  setNeedEmail: any;
  clientType: OptionType;
  needModelRef: boolean;
  setNeedModelRef: any;
  invoice: IQuittance;
}) => {
  const [addNewRow, setAddNewRow] = useState<boolean>(false);
  const [nextID, setNextID] = useState("");
  const [paymentLink, setPaymentLink] = useState("");
  const [receiverEmail, setReceiverEmail] = useState("");
  const [modelRef, setModelRef] = useState("");

  const [companyAddress, setcompanyAddress] = useState("");
  const [companyShareCapital, setcompanyShareCapital] = useState("");
  const [companyPhone, setcompanyPhone] = useState("");
  const [companySiren, setcompanySiren] = useState("");
  const [companyTVA, setcompanyTVA] = useState("");

  const [clientName, setclientName] = useState("");
  const [clientAddress, setclientAddress] = useState("");
  const [clientPhone, setclientPhone] = useState("");
  const [clientSiren, setclientSiren] = useState("");
  const [clientTVA, setclientTVA] = useState("");
  const [additionalInfo, setadditionalInfo] = useState("");

  const [signee, setSignee] = useState("");
  const [beneficiary, setBeneficiary] = useState("");
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [paymentDate, setPaymentDate] = useState<string | null>(null);
  const [loyer, setLoyer] = useState<number | null>(null);
  const [charges, setCharge] = useState<number | null>(null);
  const [month, setMonth] = useState(
    new Date(moment().startOf("month").format())
  );

  const [totalTTC, setTotalTTC] = useState(0);
  const { setDecimalDigits } = useFormatter();
  const [totalTVA, setTotalTVA] = useState(0);

  const [companyLogo, setcompanyLogo] = useState<any>();

  let api = useAxios();
  const creds = useSelector(
    (state: { root: object; user: object }) => state.root
  ) as { user_id: string; company_id: string; token: string; role: string };

  const { user, company } = useSelector(
    (state: { root: object; user: object; company: object }) => state.user
  ) as { user: User; company: Company };

  const writtenNumber = require("written-number");

  useEffect(() => {
    // setcompanyAddress(company.headOffice);
    // setcompanyPhone(company.phone);
    setcompanySiren(company.siren);
    setcompanyTVA(company.intraCommunityVATNumber);
    setcompanyShareCapital(company.shareCapital);
  }, [company]);

  useEffect(() => {
    if (invoice && invoice.id) {
      if (invoice.month) {
        setMonth(new Date(moment(invoice.month).startOf("month").format()));
      }
      //client
      if (invoice.clientInfo && invoice.clientInfo.clientEmail) {
        setReceiverEmail(invoice.clientInfo.clientEmail);
      }
      if (invoice.clientInfo && invoice.clientInfo.clientAdress) {
        setclientAddress(invoice.clientInfo.clientAdress);
      }
      if (invoice.clientInfo && invoice.clientInfo.clientTel) {
        setclientPhone(invoice.clientInfo.clientTel);
      }
      if (invoice.clientInfo && invoice.clientInfo.clientSiren) {
        setclientSiren(invoice.clientInfo.clientSiren);
      }
      setcompanyAddress(
        invoice.quittInfo && invoice.quittInfo.companyAddress
          ? invoice.quittInfo.companyAddress
          : company.headOffice
      );
      setcompanyPhone(
        invoice.quittInfo && invoice.quittInfo.companyPhone
          ? invoice.quittInfo.companyPhone
          : company.phone
      );
      if (invoice.clientInfo && invoice.clientInfo.clientTVA) {
        setclientTVA(invoice.clientInfo.clientTVA);
      }
      if (invoice.clientInfo && invoice.clientInfo.clientName) {
        setclientName(invoice.clientInfo.clientName);
      }
      //end client
      if (invoice.quittInfo && invoice.quittInfo.signee) {
        setSignee(invoice.quittInfo.signee);
      }
      if (invoice.quittInfo && invoice.quittInfo.logo) {
        setcompanyLogo(invoice.quittInfo.logo);
      }
      if (invoice.quittInfo && invoice.quittInfo.beneficiary) {
        setBeneficiary(invoice.quittInfo.beneficiary);
      }
      if (invoice.startDate) {
        setStartDate(invoice.startDate);
      }
      if (invoice.endDate) {
        setEndDate(invoice.endDate);
      }
      if (invoice.loyer) {
        setLoyer(parseFloat(invoice.loyer));
      }
      if (invoice.charges) {
        setCharge(parseFloat(invoice.charges));
      }
      if (invoice.paymentDate) {
        setPaymentDate(invoice.paymentDate);
      }
      if (invoice.totalTTC) {
        setTotalTTC(parseFloat(invoice.totalTTC));
      }
      if (invoice.totalTVA) {
        setTotalTVA(parseFloat(invoice.totalTVA));
      }
    }
  }, [invoice]);

  useEffect(() => {
    let obj: any = {
      month,
      companyAddress,
      companyPhone,
      companySiren,
      companyShareCapital,
      companyTVA,
      clientName,
      clientAddress,
      clientPhone,
      clientSiren,
      clientTVA,
      additionalInfo,
      companyLogo,
      receiverEmail,
      modelRef,
      signee,
      beneficiary,
      startDate,
      endDate,
      loyer: loyer || 0,
      charges: charges || 0,
      paymentDate,
      totalTTC: parseFloat(setDecimalDigits(totalTTC)),
    };
    if (company && company.subjectToVAT) {
      obj = {
        ...obj,
        totalTVA,
      };
    }
    setFormData(obj);
  }, [
    month,
    companyAddress,
    companyShareCapital,
    companyPhone,
    companySiren,
    companyTVA,
    clientName,
    clientAddress,
    clientPhone,
    clientSiren,
    clientTVA,
    additionalInfo,
    companyLogo,
    receiverEmail,
    modelRef,
    signee,
    beneficiary,
    startDate,
    endDate,
    loyer,
    charges,
    paymentDate,
    totalTTC,
    totalTVA,
  ]);

  useEffect(() => {
    let ttc = (loyer || 0) + (charges || 0);
    if (company && company.subjectToVAT) {
      ttc = (ttc || 0) + totalTVA;
    }
    setTotalTTC(ttc);
  }, [loyer, charges, totalTVA]);

  const hiddenFileInput = React.useRef(null);

  const handleClick = (event: any) => {
    (hiddenFileInput.current as any).click();
  };

  const handleChange = (event: { target: { files: any[] } }) => {
    const fileUploaded = event.target.files[0];
    setcompanyLogo(fileUploaded);
  };

  return (
    <>
      <div className="facture-Wrrap">
        <h2 className="quittance-title">
          Quittance de loyer du mois de{" "}
          <DatePicker
            onChange={(date: any) => {
              setMonth(date);
            }}
            selected={new Date(month)}
            locale="fr"
            dateFormat="MMMM yyyy"
            showMonthYearPicker
            showFullMonthYearPicker
          />
        </h2>
        <div className="Header-fact">
          <div className="left-head-fact">
            <div className="titleNUm-fact">
              <span className="titlenum-fact">Quittance n° :</span>
              <span className="num-fact">
                {invoice && invoice.id.toUpperCase()}
              </span>
              <span className="date-fact">
                Date: {moment().format("DD/MM/YYYY")}
              </span>
            </div>
          </div>
          <div className="right-head-fact">
            {/* <img src={LogoNoly} alt="logo" /> */}
            <div className="invoice-logo user-info mb-4">
              {companyLogo && (
                <>
                  <CerleUserBg
                    className={"bgCercle"}
                    fillColor={colors.primaryColor}
                  />
                  <img
                    src={
                      typeof companyLogo === "string"
                        ? companyLogo
                        : URL.createObjectURL(companyLogo as any)
                    }
                    alt="logo"
                  />
                </>
              )}
            </div>
            <Button
              type="button"
              className="form__button"
              color="secondary"
              onClick={handleClick}
            >
              Editer le logo
            </Button>

            <input
              ref={hiddenFileInput}
              onChange={handleChange as any}
              type="file"
              className="file-input"
            />
          </div>
        </div>

        <div className="Header-fact-infos">
          <div className="left-infos-fact">
            <div className="box-fact-edit ">
              <h3>{company?.name || ""}</h3>
              <FormGroup className="form-icon">
                <Label for="adress">Adresse :</Label>
                <Input
                  id="adress"
                  type="text"
                  className="form-default"
                  value={companyAddress}
                  onChange={(e) => setcompanyAddress(e.target.value)}
                />
              </FormGroup>

              <FormGroup className="form-icon">
                <Label for="phone">Teléphone :</Label>
                <Input
                  type="text"
                  className="form-default"
                  value={companyPhone}
                  onChange={(e) => setcompanyPhone(e.target.value)}
                />
              </FormGroup>
              <FormGroup className="form-icon">
                <Label for="siren">Siren :</Label>
                <Input
                  id="siren"
                  type="text"
                  className="form-default"
                  value={companySiren}
                  onChange={(e) => setcompanySiren(e.target.value)}
                  disabled
                />
              </FormGroup>
              <FormGroup className="form-icon">
                <Label for="numtva">Numéro TVA :</Label>
                <Input
                  id="numtva"
                  type="text"
                  className="form-default"
                  value={companyTVA}
                  onChange={(e) => setcompanyTVA(e.target.value)}
                  disabled
                />
              </FormGroup>
              <FormGroup className="form-icon">
                <Label for="capital">Capital social :</Label>
                <Input
                  id="capital"
                  type="text"
                  className="form-default"
                  value={companyShareCapital}
                  onChange={(e) => setcompanyShareCapital(e.target.value)}
                  disabled
                />
              </FormGroup>
            </div>
          </div>
          <div className="right-infos-fact">
            <div className="box-fact-edit ">
              <input
                className="form-fact clientName-input"
                placeholder="..."
                value={clientName}
                onChange={(e) => setclientName(e.target.value)}
              />

              <FormGroup className="form-icon">
                <Label for="adress">Adresse :</Label>
                <Input
                  id="adress"
                  type="text"
                  className="form-primary"
                  value={clientAddress}
                  onChange={(e) => setclientAddress(e.target.value)}
                />
              </FormGroup>

              <FormGroup className="form-icon">
                <Label for="phone">Teléphone :</Label>
                <Input
                  id="phone"
                  type="text"
                  className="form-primary"
                  value={clientPhone}
                  onChange={(e) => setclientPhone(e.target.value)}
                />
              </FormGroup>
              {clientType && clientType.value === ClientTypes.B2B ? (
                <>
                  <FormGroup className="form-icon">
                    <Label for="siren">Siren :</Label>
                    <Input
                      id="siren"
                      type="text"
                      className="form-primary"
                      value={clientSiren}
                      onChange={(e) => setclientSiren(e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup className="form-icon">
                    <Label for="numtva">Numéro TVA :</Label>
                    <Input
                      id="numtva"
                      type="text"
                      className="form-primary"
                      value={clientTVA}
                      onChange={(e) => setclientTVA(e.target.value)}
                    />
                  </FormGroup>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>

        <div className="infos-additionnels">
          <div className="text-added">
            <p className="form-fact quitance-form">
              Je soussigné(e),{" "}
              <input
                placeholder="..............................................."
                value={signee}
                onChange={(e) => setSignee(e.target.value)}
                className="text-input"
                type="text"
              />
              , représentant(e) de la <span>{company?.name || ""}</span>,
              propriétaire du logement désigné ci-dessus, déclare avoir reçu de{" "}
              <input
                placeholder="..............................................."
                value={beneficiary}
                onChange={(e) => setBeneficiary(e.target.value)}
                className="text-input"
                type="text"
              />
              , la somme de {totalTTC} € (
              {writtenNumber(totalTTC, {
                lang: "fr",
              })}{" "}
              euros), au titre du paiement du loyer et des charges pour la
              période de location du{" "}
              <DatePicker
                placeholderText="DD/MM/YYYY"
                onChange={(date: any) => {
                  setStartDate(date);
                }}
                selected={startDate ? new Date(startDate) : null}
                locale="fr"
                dateFormat="dd/MM/yyyy"
                id="quitance-date-picker"
              />{" "}
              au{" "}
              <DatePicker
                placeholderText="DD/MM/YYYY"
                onChange={(date: any) => {
                  setEndDate(date);
                }}
                selected={endDate ? new Date(endDate) : null}
                locale="fr"
                dateFormat="dd/MM/yyyy"
                id="quitance-date-picker"
              />{" "}
              et lui en donne quittance, sous réserve de tous mes droits.
            </p>
          </div>
        </div>

        <div className="quittance-resultat-table">
          <span className="title">Détail du règlement : </span>
          <div className="inner-total">
            <div className="quittance-item-totat">
              <span className="label-total">Loyer : </span>
              <span className="num-total">
                <input
                  placeholder="0"
                  defaultValue={loyer || ""}
                  onChange={(e) => setLoyer(e.target.valueAsNumber)}
                  type="number"
                  min={0}
                  onWheel={(e) => (e.target as any).blur()}
                />{" "}
                €
              </span>
            </div>
            <div className="quittance-item-totat">
              <span className="label-total">Provision pour charges : </span>
              <span className="num-total">
                <input
                  placeholder="0"
                  defaultValue={charges || ""}
                  onChange={(e) => setCharge(e.target.valueAsNumber)}
                  min={0}
                  type="number"
                  onWheel={(e) => (e.target as any).blur()}
                />
                €
              </span>
            </div>
            {company && company.subjectToVAT && (
              <div className="quittance-item-totat">
                <span className="label-total">TVA : </span>
                <span className="num-total">
                  <input
                    placeholder="0"
                    defaultValue={totalTVA || ""}
                    onChange={(e) => setTotalTVA(e.target.valueAsNumber)}
                    min={0}
                    type="number"
                    onWheel={(e) => (e.target as any).blur()}
                  />
                  €
                </span>
              </div>
            )}
            <div className="quittance-item-totat total-ttc">
              <span className="label-total">Total :</span>
              <span className="num-total">
                {setDecimalDigits(totalTTC)} € €
              </span>
            </div>
            <div>
              <div className="quittance-item-totat">
                <span className="label-total">Date du paiement : le</span>
                <span className="num-total quitance-payment-date">
                  <DatePicker
                    placeholderText="DD/MM/YYYY"
                    onChange={(date: any) => {
                      setPaymentDate(date);
                    }}
                    selected={paymentDate ? new Date(paymentDate) : null}
                    locale="fr"
                    dateFormat="dd/MM/yyyy"
                    id="quitance-date-picker"
                  />
                  {/* <input
                    className="payment-date-input"
                    placeholder="DD/MM/YYYY"
                    value={paymentDate}
                    onChange={(e) => setPaymentDate(e.target.value)}
                  /> */}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-fact">
          <span>Fiinex</span>
        </div>
      </div>

      <div className="outer-wrap-bottom">
        <FormGroup className="form-icon icon-start">
          <Label for="lab">Ajouter l'email du destinataire</Label>
          <Input
            id="lab"
            name="lab"
            placeholder="Email"
            type="text"
            className={`form-primary ${
              needEmail ? "invalid-custom-input" : ""
            }`}
            value={receiverEmail}
            onChange={(e) => {
              setReceiverEmail(e.target.value);
              if (needEmail) {
                setNeedEmail(false);
              }
            }}
          />

          <span className="icon icon-primary ">
            {<ReactSVG src={EmailIcon} />}
          </span>
        </FormGroup>
        {needEmail && (
          <span className="text-center text-danger">
            Une adresse email valide est requise
          </span>
        )}
        {needModelRef && (
          <FormGroup className="form-icon icon-start">
            <Label for="lab">Ajouter le nom du modèle</Label>
            <Input
              id="lab"
              name="lab"
              placeholder="Nom du modèle"
              type="text"
              className={`form-primary ${
                modelRef === "" ? "invalid-custom-input" : ""
              }`}
              value={modelRef}
              onChange={(e) => {
                setModelRef(e.target.value);
              }}
            />

            <span className="icon icon-primary ">
              <FaFileInvoice />
            </span>
          </FormGroup>
        )}
      </div>
    </>
  );
};

export default LoadQuittance;

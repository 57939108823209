import React, { useEffect, useId, useState } from "react";
import "./CreateQuittance.scss";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import {
  Button,
  Card,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import {
  RiAddFill,
  RiCameraLine,
  RiCloseCircleFill,
  RiLinkM,
  RiSave3Fill,
} from "react-icons/ri";
import EmailIcon from "../../../assets/images/svg/mail-green.svg";
import moment from "moment";
import "moment/locale/fr";
import useAxios from "../../../util/hooks/useAxios";
import { useSelector } from "react-redux";
import { ErrorLogger } from "../../../util/errorLogger";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Company, ISubCategory, User } from "../../../interfaces";
import { useFormatter } from "../../../util/hooks/useFormatter";
import { ClientTypes, OptionType } from "../../../util/context";
import CerleUserBg from "../../../assets/CerleUserBg";
import { colors } from "../../../constants";
import { FaFileInvoice } from "react-icons/fa";
import { ReactSVG } from "react-svg";
registerLocale("fr", fr);

export interface FactureCreatProps {}

const CreateQuittance = ({
  model,
  setFormData,
  needEmail,
  setNeedEmail,
  needModelRef,
  setNeedModelRef,
  clientType,
}: {
  model: string;
  invoiceSubCategs: ISubCategory[];
  formData: any;
  setFormData: any;
  needEmail: boolean;
  setNeedEmail: any;
  needModelRef: boolean;
  setNeedModelRef: any;
  clientType: OptionType;
}) => {
  const [nextID, setNextID] = useState("");
  const [receiverEmail, setReceiverEmail] = useState("");
  const [modelRef, setModelRef] = useState("");

  const [companyAddress, setcompanyAddress] = useState("");
  const [companyShareCapital, setcompanyShareCapital] = useState("");
  const [companyPhone, setcompanyPhone] = useState("");
  const [companySiren, setcompanySiren] = useState("");
  const [companyTVA, setcompanyTVA] = useState("");
  const [clientName, setclientName] = useState("");
  const [clientAddress, setclientAddress] = useState("");
  const [clientPhone, setclientPhone] = useState("");
  const [clientSiren, setclientSiren] = useState("");
  const [clientTVA, setclientTVA] = useState("");
  const [additionalInfo, setadditionalInfo] = useState("");
  const [signee, setSignee] = useState("");
  const [beneficiary, setBeneficiary] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [paymentDate, setPaymentDate] = useState(null);
  const [loyer, setLoyer] = useState<number | null>(null);
  const [charges, setCharge] = useState<number | null>(null);
  const [month, setMonth] = useState(
    new Date(moment().startOf("month").format())
  );

  const [totalTTC, setTotalTTC] = useState(0);
  const [totalTVA, setTotalTVA] = useState(0);
  const { setDecimalDigits } = useFormatter();

  const [companyLogo, setcompanyLogo] = useState<any>();

  const writtenNumber = require("written-number");

  let api = useAxios();
  const creds = useSelector(
    (state: { root: object; user: object }) => state.root
  ) as { user_id: string; company_id: string; token: string; role: string };

  const { user, company } = useSelector(
    (state: { root: object; user: object; company: object }) => state.user
  ) as { user: User; company: Company };

  const getNextInvoiceID = async () => {
    try {
      const { data } = await api.post(
        `/api/invoice/getNextInvoiceID`,
        {
          model: "Quittance",
          company_id: creds.company_id,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );
      if (data.id) {
        setNextID(data.id);
      }
    } catch (error: any) {
      ErrorLogger("getting invoice nex id", error);
    }
  };

  useEffect(() => {
    setcompanyAddress(company.headOffice);
    setcompanyPhone(company.phone);
    setcompanySiren(company.siren);
    setcompanyTVA(company.intraCommunityVATNumber);
    setcompanyShareCapital(company.shareCapital);
  }, [company]);

  useEffect(() => {
    let obj: any = {
      month,
      companyAddress,
      companyPhone,
      companySiren,
      companyShareCapital,
      companyTVA,
      clientName,
      clientAddress,
      clientPhone,
      clientSiren,
      clientTVA,
      additionalInfo,
      companyLogo,
      receiverEmail,
      modelRef,
      signee,
      beneficiary,
      startDate,
      endDate,
      loyer: loyer || 0,
      charges: charges || 0,
      paymentDate,
      totalTTC: parseFloat(setDecimalDigits(totalTTC)),
    };
    if (company && company.subjectToVAT) {
      obj = {
        ...obj,
        totalTVA,
      };
    }
    setFormData(obj);
  }, [
    month,
    companyAddress,
    companyShareCapital,
    companyPhone,
    companySiren,
    companyTVA,
    clientName,
    clientAddress,
    clientPhone,
    clientSiren,
    clientTVA,
    additionalInfo,
    companyLogo,
    receiverEmail,
    modelRef,
    signee,
    beneficiary,
    startDate,
    endDate,
    loyer,
    charges,
    paymentDate,
    totalTTC,
    totalTVA,
  ]);

  useEffect(() => {
    let ttc = (loyer || 0) + (charges || 0);
    if (company && company.subjectToVAT) {
      ttc = (ttc || 0) + totalTVA;
    }
    setTotalTTC(ttc);
  }, [loyer, charges, totalTVA]);

  useEffect(() => {
    getNextInvoiceID();
  }, []);

  const hiddenFileInput = React.useRef(null);

  const handleClick = (event: any) => {
    (hiddenFileInput.current as any).click();
  };

  const handleChange = (event: { target: { files: any[] } }) => {
    const fileUploaded = event.target.files[0];
    setcompanyLogo(fileUploaded);
  };

  return (
    <>
      <div className="facture-Wrrap">
        <h2 className="quittance-title">
          Quittance de loyer du mois de{" "}
          <DatePicker
            onChange={(date: any) => {
              setMonth(date);
            }}
            selected={new Date(month)}
            locale="fr"
            dateFormat="MMMM yyyy"
            showMonthYearPicker
            showFullMonthYearPicker
          />
        </h2>

        <div className="Header-fact">
          <div className="left-head-fact">
            <div className="titleNUm-fact">
              <span className="titlenum-fact">Quittance n° :</span>
              <span className="num-fact">{nextID.toUpperCase()}</span>
              <span className="date-fact">
                Date: {moment().format("DD/MM/YYYY")}
              </span>
            </div>
          </div>
          <div className="right-head-fact">
            <div className="invoice-logo user-info mb-4">
              {companyLogo && (
                <>
                  <CerleUserBg
                    className={"bgCercle"}
                    fillColor={colors.primaryColor}
                  />
                  <img
                    src={URL.createObjectURL(companyLogo as any)}
                    alt="logo"
                  />
                </>
              )}
            </div>
            <Button
              type="button"
              className="form__button"
              color="secondary"
              onClick={handleClick}
            >
              Ajouter un logo
            </Button>

            <input
              ref={hiddenFileInput}
              onChange={handleChange as any}
              type="file"
              className="file-input"
            />
          </div>
        </div>

        <div className="Header-fact-infos">
          <div className="left-infos-fact">
            <div className="box-fact-edit ">
              <h3>{company?.name || ""}</h3>
              <FormGroup className="form-icon">
                <Label for="adress">Adresse :</Label>
                <Input
                  id="adress"
                  type="text"
                  className="form-default"
                  value={companyAddress}
                  onChange={(e) => setcompanyAddress(e.target.value)}
                />
              </FormGroup>

              <FormGroup className="form-icon">
                <Label for="phone">Teléphone :</Label>
                <Input
                  type="text"
                  className="form-default"
                  value={companyPhone}
                  onChange={(e) => setcompanyPhone(e.target.value)}
                />
              </FormGroup>
              <FormGroup className="form-icon">
                <Label for="siren">Siren :</Label>
                <Input
                  id="siren"
                  type="text"
                  className="form-default"
                  value={companySiren}
                  onChange={(e) => setcompanySiren(e.target.value)}
                  disabled
                />
              </FormGroup>
              <FormGroup className="form-icon">
                <Label for="numtva">Numéro TVA :</Label>
                <Input
                  id="numtva"
                  type="text"
                  className="form-default"
                  value={companyTVA}
                  onChange={(e) => setcompanyTVA(e.target.value)}
                  disabled
                />
              </FormGroup>
              <FormGroup className="form-icon">
                <Label for="capital">Capital social :</Label>
                <Input
                  id="capital"
                  type="text"
                  className="form-default"
                  value={companyShareCapital}
                  onChange={(e) => setcompanyShareCapital(e.target.value)}
                  disabled
                />
              </FormGroup>
            </div>
          </div>
          <div className="right-infos-fact">
            <div className="box-fact-edit ">
              <input
                className="form-fact clientName-input"
                placeholder="..."
                value={clientName}
                onChange={(e) => setclientName(e.target.value)}
              />

              <FormGroup className="form-icon">
                <Label for="adress">Adresse :</Label>
                <Input
                  id="adress"
                  type="text"
                  className="form-primary"
                  value={clientAddress}
                  onChange={(e) => setclientAddress(e.target.value)}
                />
              </FormGroup>

              <FormGroup className="form-icon">
                <Label for="phone">Teléphone :</Label>
                <Input
                  id="phone"
                  type="text"
                  className="form-primary"
                  value={clientPhone}
                  onChange={(e) => setclientPhone(e.target.value)}
                />
              </FormGroup>
              {clientType && clientType.value === ClientTypes.B2B ? (
                <>
                  <FormGroup className="form-icon">
                    <Label for="siren">Siren :</Label>
                    <Input
                      id="siren"
                      type="text"
                      className="form-primary"
                      value={clientSiren}
                      onChange={(e) => setclientSiren(e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup className="form-icon">
                    <Label for="numtva">Numéro TVA :</Label>
                    <Input
                      id="numtva"
                      type="text"
                      className="form-primary"
                      value={clientTVA}
                      onChange={(e) => setclientTVA(e.target.value)}
                    />
                  </FormGroup>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>

        <div className="infos-additionnels">
          <div className="text-added">
            <p className="form-fact quitance-form">
              Je soussigné(e),{" "}
              <input
                placeholder="..............................................."
                value={signee}
                onChange={(e) => setSignee(e.target.value)}
                className="text-input"
                type="text"
              />
              , représentant(e) de la <span>{company?.name || ""}</span>,
              propriétaire du logement désigné ci-dessus, déclare avoir reçu de{" "}
              <input
                placeholder="..............................................."
                value={beneficiary}
                onChange={(e) => setBeneficiary(e.target.value)}
                className="text-input"
                type="text"
              />
              , la somme de {totalTTC} € (
              {writtenNumber(totalTTC, {
                lang: "fr",
              })}{" "}
              euros), au titre du paiement du loyer et des charges pour la
              période de location du{" "}
              <DatePicker
                placeholderText="DD/MM/YYYY"
                onChange={(date: any) => {
                  setStartDate(date);
                }}
                selected={startDate ? new Date(startDate) : null}
                locale="fr"
                dateFormat="dd/MM/yyyy"
                id="quitance-date-picker"
              />{" "}
              au{" "}
              <DatePicker
                placeholderText="DD/MM/YYYY"
                onChange={(date: any) => {
                  setEndDate(date);
                }}
                selected={endDate ? new Date(endDate) : null}
                locale="fr"
                dateFormat="dd/MM/yyyy"
                id="quitance-date-picker"
              />{" "}
              et lui en donne quittance, sous réserve de tous mes droits.
            </p>
          </div>
        </div>

        <div className="quittance-resultat-table">
          <span className="title">Détail du règlement : </span>
          <div className="inner-total">
            <div className="quittance-item-totat">
              <span className="label-total">Loyer : </span>
              <span className="num-total">
                <input
                  placeholder="0"
                  defaultValue={loyer || ""}
                  onChange={(e) => setLoyer(e.target.valueAsNumber)}
                  type="number"
                  min={0}
                  onWheel={(e) => (e.target as any).blur()}
                />{" "}
                €
              </span>
            </div>
            <div className="quittance-item-totat">
              <span className="label-total">Provision pour charges : </span>
              <span className="num-total">
                <input
                  placeholder="0"
                  defaultValue={charges || ""}
                  onChange={(e) => setCharge(e.target.valueAsNumber)}
                  min={0}
                  type="number"
                  onWheel={(e) => (e.target as any).blur()}
                />
                €
              </span>
            </div>
            {company && company.subjectToVAT && (
              <div className="quittance-item-totat">
                <span className="label-total">TVA : </span>
                <span className="num-total">
                  <input
                    placeholder="0"
                    defaultValue={totalTVA || ""}
                    onChange={(e) => setTotalTVA(e.target.valueAsNumber)}
                    min={0}
                    type="number"
                    onWheel={(e) => (e.target as any).blur()}
                  />
                  €
                </span>
              </div>
            )}
            <div className="quittance-item-totat total-ttc">
              <span className="label-total">Total :</span>
              <span className="num-total">
                {setDecimalDigits(totalTTC)} € €
              </span>
            </div>
            <div>
              <div className="quittance-item-totat">
                <span className="label-total">Date du paiement : le</span>
                <span className="num-total quitance-payment-date">
                  <DatePicker
                    placeholderText="DD/MM/YYYY"
                    onChange={(date: any) => {
                      setPaymentDate(date);
                    }}
                    selected={paymentDate ? new Date(paymentDate) : null}
                    locale="fr"
                    dateFormat="dd/MM/yyyy"
                    id="quitance-date-picker"
                  />
                  {/* <input
                    className="payment-date-input"
                    placeholder="DD/MM/YYYY"
                    value={paymentDate}
                    onChange={(e) => setPaymentDate(e.target.value)}
                  /> */}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-fact">
          <span>Fiinex</span>
        </div>
      </div>

      <div className="outer-wrap-bottom">
        <FormGroup className="form-icon icon-start">
          <Label for="lab">Ajouter l'email du destinataire</Label>
          <Input
            id="lab"
            name="lab"
            placeholder="Email"
            type="text"
            className={`form-primary ${
              needEmail ? "invalid-custom-input" : ""
            }`}
            value={receiverEmail}
            onChange={(e) => {
              setReceiverEmail(e.target.value);
              if (needEmail) {
                setNeedEmail(false);
              }
            }}
          />

          <span className="icon icon-primary ">
            {<ReactSVG src={EmailIcon} />}
            {/* <img src={EmailIcon} alt="icon" /> */}
          </span>
        </FormGroup>
        {needEmail && (
          <span className="text-center text-danger">
            Une adresse email valide est requise
          </span>
        )}
        {needModelRef && (
          <FormGroup className="form-icon icon-start">
            <Label for="lab">Ajouter le nom du modèle</Label>
            <Input
              id="lab"
              name="lab"
              placeholder="Nom du modèle"
              type="text"
              className={`form-primary ${
                modelRef === "" ? "invalid-custom-input" : ""
              }`}
              value={modelRef}
              onChange={(e) => {
                setModelRef(e.target.value);
              }}
              invalid={modelRef === ""}
            />

            <span className="icon icon-primary ">
              <FaFileInvoice />
            </span>
          </FormGroup>
        )}
      </div>
    </>
  );
};

export default CreateQuittance;
